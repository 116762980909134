'use server';

import client from '@/lib/api.ts';

export default async function checkoutPayment(body: Record<string, any>, orderId?: string | null) {
    if (!orderId) {
        return { ok: false };
    }

    const res = await client.api(`/api/public/invoices/${orderId}/payment/checkout`, {
        method: 'POST',
        body: JSON.stringify(body),
    });

    const data = await res.json();

    if (res.ok && res.status === 201) {
        return { ok: true, data: data };
    }

    return { ok: false, data: data };
}
