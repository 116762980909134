import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';

export const variants = cva(['flex', 'gap-6', 'border', 'p-6', 'lg:py-12', 'flex-col', 'md:flex-row'], {
    variants: {
        color: {
            green: ['bg-success/10', 'border-success', 'text-success'],
            blue: ['bg-primary-500/10', 'border-primary-500', 'text-primary-500'],
            yellow: ['bg-warning-light', 'border-warning', 'text-warning'],
            white: ['bg-white', 'text-primary-600'],
        },
    },
    defaultVariants: {
        color: 'white',
    },
});

export type ComponentVariantProps = VariantProps<typeof variants>;
