'use client';

import { useTranslation } from '@/lib/i18n.ts';
import { Button, DialogContent, DialogTitle, Loading, Text, useDialog } from '@expanzi/ui-library';
import { Checkmark, CrossInCircle } from '@/svg';
import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import checkoutPayment from './actions.ts';
import { queryClient } from '@/lib/store.ts';
import { TOKEN_PARAM } from '@/constants/params.ts';

type Props = {
    searchParams?: string;
};

export default function VerifyPaymentDialog(props: Props) {
    const { searchParams = '' } = props;

    const { t } = useTranslation();
    const { Dialog } = useDialog();

    const params = new URLSearchParams(searchParams);

    const dialogOpen = Boolean(params.get('OPERATION'));
    const orderNumber = params.get('ORDERNUMBER');
    const status = params.get('PRCODE');

    const paymentMutation = useMutation(
        {
            mutationFn: () =>
                checkoutPayment(
                    {
                        operation: params.get('OPERATION'),
                        orderNumber: Number(orderNumber),
                        prCode: Number(status),
                        srCode: Number(params.get('PRCODE')),
                        resultText: params.get('RESULTTEXT'),
                        digest: params.get('DIGEST'),
                        digest1: params.get('DIGEST1'),
                    },
                    params.get('MERORDERNUM'),
                ),
            onSuccess: (data) => {
                if (data.ok) {
                    if (data.data.items.length === 1 && data.data.items[0].product.virtualProduct) {
                        window.location.href = `${import.meta.env.PUBLIC_OFFICE_URL}`;
                        return;
                    }

                    window.location.href = `/orders/${data.data?.id}?${TOKEN_PARAM}=${data?.data.accessToken}`;
                }
            },
        },
        queryClient,
    );

    useEffect(() => {
        paymentMutation.mutate();
    }, []);

    const isError = !paymentMutation.data?.ok && status === '0';
    const alreadyPaidError = paymentMutation?.data?.data.detail?.includes(
        'Invoice is not in CREATED state, but in PAID state',
    );

    return (
        <Dialog
            open={dialogOpen}
            onOpenChange={() => {
                window.location.href = '/';
            }}
        >
            <DialogContent>
                {paymentMutation.isPending ? (
                    <div className="flex items-center justify-center">
                        <Loading className="w-20 py-16" />
                    </div>
                ) : isError ? (
                    <>
                        <CrossInCircle className="text-danger mx-auto mb-6" />
                        <DialogTitle>
                            {alreadyPaidError
                                ? t('payment_validation.orderAlreadyPaid')
                                : t('payment_validation.failed')}
                        </DialogTitle>
                        {!alreadyPaidError && (
                            <>
                                <Text.Body
                                    className="mb-6 text-center"
                                    dangerouslySetInnerHTML={{ __html: t('payment_validation.failedDescription') }}
                                />
                                <Button
                                    className="w-full"
                                    rounded
                                    type="button"
                                    loading={paymentMutation.isPending}
                                    onClick={() => paymentMutation.mutate()}
                                >
                                    {t('payment_validation.tryAgain')}
                                </Button>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Checkmark className="text-success mx-auto mb-6" />
                        <DialogTitle>{t('payment_validation.success')}</DialogTitle>
                        <Text.Body className="mb-6 text-center">{t('payment_validation.redirect')}</Text.Body>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}
